import {
	ApiCurrenciesSettings,
	CurrenciesSettings,
	CurrencySettings,
} from '@legacyApp/types/wallet/CurrenciesSettings';
import {
	CurrencyTokenType,
	CurrencyType,
} from '@legacyApp/types/wallet/CurrencyType';
import { loop } from '@legacyApp/client/modules/app/appService';
import { sentryError } from '@legacyApp/client/modules/app/sentryService';
import { parseApiUrl } from '@legacyApp/methods/fetch/parseApiUrl';
import { get } from '@legacyApp/client/modules/app/axios';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { hour } from '@legacyApp/client/modules/app/time';
import { lsSet } from '@modules/localStorage/methods/lsSet';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { jsonToString } from '@common/methods/jsonToString';
import { generateUrl, ROUTE } from '../../routes';
import { TokenNames } from '../../constants/currency/settings/tokenNames';
import sortService from '../../../LegacyApp/client/modules/app/sortService';
import { CURRENCIES_LIST } from '../../constants/currency/currenciesList';

export const CURRENCIES_SETTINGS_UPDATE_INTERVAL = hour;
export const DEFAULT_CURRENCY = 'btc' as const;

export class CurrenciesSettingsClass {
	list: readonly CurrencyType[];
	allList: readonly (CurrencyTokenType | 'usd' | 'eur')[];
	settings: CurrenciesSettings;

	constructor() {
		this.list = CURRENCIES_LIST;
		this.allList = [
			...(Object.keys(TokenNames) as CurrencyTokenType[]),
			'usd',
			'eur',
		];
		this.settings = lsGet(LocalStorageKeys.CURRENCIES_SETTINGS, {});
		this.initInterval();
	}

	async fetchSettings(): Promise<CurrenciesSettings> {
		const settings: CurrenciesSettings = JSON.parse(
			JSON.stringify(this.settings),
		);

		const response: {
			data: {
				settings: Record<CurrencyType, ApiCurrenciesSettings> | {};
				error?: any;
			};
		} = await get(parseApiUrl(generateUrl(ROUTE.apiSiteCurrencies))).catch(
			(error) => {
				// sentryError(new Error('Error - axios.get currencies settings'), {
				// 	error,
				// 	_: this,
				// });
				// console.log(error);
				return { data: { settings: {}, error } };
			},
		);

		// if (!NEXT_CONFIG.PRODUCTION) {
		// 	response.data = {
		// 		btc: {
		// 			tip: {
		// 				amount_minimum: 1,
		// 			},
		// 			rain: {
		// 				amount_minimum: 1,
		// 			},
		// 			casino: {
		// 				amount_flash_minimum: 1,
		// 				amount_maximum: 1,
		// 				profit_maximum: 1,
		// 			},
		// 			sports: {
		// 				amount_minimum: 1,
		// 				amount_maximum: 1,
		// 				profit_maximum: 1,
		// 			},
		// 			chat: {
		// 				wager_minimum: 1,
		// 				profit_minimum: 1,
		// 			},
		// 		},
		// 		eth: {
		// 			tip: {
		// 				amount_minimum: 1,
		// 			},
		// 			rain: {
		// 				amount_minimum: 1,
		// 			},
		// 			casino: {
		// 				amount_flash_minimum: 1,
		// 				amount_maximum: 1,
		// 				profit_maximum: 1,
		// 			},
		// 			sports: {
		// 				amount_minimum: 1,
		// 				amount_maximum: 1,
		// 				profit_maximum: 1,
		// 			},
		// 			chat: {
		// 				wager_minimum: 1,
		// 				profit_minimum: 1,
		// 			},
		// 		}
		// 		// minTip: 0.07,
		// 		// minRain: 0.7,
		// 		// minDeposit: null,
		// 		// minWithdraw: 5,
		// 		// maxWithdrawPrecision: 6,
		// 		// minFlashAmount: 0.0014,
		// 		// betLimits: {
		// 		// 	max: 69000,
		// 		// 	maxProfit: 6900,
		// 		// },
		// 		// sportsLimits: {
		// 		// 	max: 69000,
		// 		// 	maxProfit: 6900,
		// 		// 	min: 0.007,
		// 		// },
		// 		// chat: {
		// 		// 	minWager: 0.35,
		// 		// 	minProfit: 0.18,
		// 		// },
		// 	};
		// }

		await loop(Object.keys(response.data?.settings || {}), (currency) => {
			settings[currency] = {
				...settings[currency],
				...response.data?.settings?.[currency],
				// casino: {
				// 	...response.data?.settings[currency].casino,
				// 	profit_maximum: response.data?.settings[currency].casino.profit_maximum + (getTimestamp() / 1000000000),
				// }
			};
		});

		// console.log('fetchSettings', {
		// 	data: response.data,
		// 	settings,
		// });

		if (!this.checkSettings(settings)) {
			sentryError(
				response.data?.error ||
					new Error('Invalid currencies settings fetched from API'),
				{
					settings: jsonToString(settings),
					response: jsonToString(response),
					_: this,
				},
			);
		} else {
			lsSet(LocalStorageKeys.CURRENCIES_SETTINGS, settings);
		}

		return settings;
	}

	checkSettings(settings: CurrenciesSettings) {
		return (
			settings &&
			Object.keys(settings).findIndex(
				(currencyKey) => !settings[currencyKey]?.casino?.profit_maximum,
			) === -1
		);
	}

	setSettings(settings: CurrenciesSettings) {
		// console.log('setSettings', { settings });
		this.settings = settings;
		if (!this.checkSettings(settings)) {
			sentryError(new Error('Invalid currencies settings'), {
				settings: jsonToString(settings),
				_: this,
			});
		}
	}

	async updateSettings() {
		const settings = await this.fetchSettings();
		this.setSettings(settings);
	}

	async initInterval() {
		this.updateSettings();
		setTimeout(async () => {
			this.initInterval();
		}, CURRENCIES_SETTINGS_UPDATE_INTERVAL);
	}

	getAllSettings() {
		// console.log('getAllSettings', this.settings);
		return this.settings;
	}

	getSettings(currency?: CurrencyType): CurrencySettings {
		const key = currency ? (currency.toLowerCase() as CurrencyType) : null;
		// if (!this.settings[key]) {
		// 	sentryError(new Error('Missing currency settings'), {
		// 		currency, key, settings: this.settings
		// 	});
		// }
		return this.settings[key] || {};
	}

	sortCurrency(dir, a, b) {
		const indexA = this.list.indexOf(a);
		const indexB = this.list.indexOf(b);
		const direction = sortService.getDirection(dir);

		return (indexA - indexB) * direction;
	}
}

export const currenciesSettings = new CurrenciesSettingsClass();

export const mapApiCurrencyToLayoutCurrency = (
	currency: CurrencyTokenType | CurrencyType,
): CurrencyType => {
	if (!currency) {
		return null;
	}
	switch (currency.toLowerCase()) {
		case 'usdt': {
			return 'usd';
		}
		case 'busd': {
			return 'usd';
		}
		case 'usdc': {
			return 'usd';
		}
		case 'matic': {
			return 'pol';
		}
		default: {
			return currency as CurrencyType;
		}
	}
};

export const mapLayoutCurrencyToApiCurrency = (
	currency: CurrencyType,
): CurrencyType => {
	if (!currency) {
		return null;
	}
	switch (currency.toLowerCase()) {
		case 'usd': {
			return 'usdt';
		}
		case 'pol': {
			return 'matic';
		}
		default: {
			return currency.toLowerCase() as CurrencyType;
		}
	}
};
