import { FC } from 'react';
import Icon from '@common/components/icons/Icon';
import {
	CurrencyTokenType,
	CurrencyType,
} from '@legacyApp/types/wallet/CurrencyType';

import styles from '@legacyApp/client/components/currencySymbol/tokenSymbol.module.scss';
import { mapApiCurrencyToLayoutCurrency } from '@common/controllers/currency/currenciesSettings';

export const TokenSymbol: FC<{
	token: CurrencyType | CurrencyTokenType;
	classes?: string;
}> = ({ token, classes }) => {
	if (!token) {
		return null;
	}
	const token_ = mapApiCurrencyToLayoutCurrency(token);
	return (
		<div className={`currency-logo ${styles.currencyLogo}`} data-token={token_}>
			<Icon
				data-name={token_}
				className={classes}
				id={`currencies/${token_}`}
			/>
		</div>
	);
};

export const CSSClassNameCurrencyLogo = 'currency-logo';
